import './vendors/h5bp';

/* 
 * Mapa mediante la API de Google Maps
 */
 function showDescription() {

  $(".yoga-types a").on("click", function(e) { e.preventDefault();
    var description = $(this).attr("href");
    $(".yoga-types a").removeClass("selected");
    $(this).addClass("selected");
    $(".aboutOrnella .description div").fadeOut(function(){
      $(description).fadeIn();  
    });
    // alert($(this).attr("href"));
  });
 
}


function googleMaps() {
  var markers = [
      ['<h5>Hotel Circulo</h5><ul><li class="icon-location">Belgrano 157</li><li class="icon-phone">0343 431-0411</li><li class="icon-clock">Miércoles de 18:30 a 20:00hs</li></ul>', -31.735606, -60.526811],
      ['<h5>La Esquina Espacio en Movimiento</h5><ul><li class="icon-location">Montevideo y Libertad</li><li class="icon-phone">3434280074 / 4075224</li><li class="icon-clock">Lunes y Jueves 10:00 a 11:00hs</li></ul>', -31.735972, -60.536673],
      ['<h5>EDA</h5><ul><li class="icon-location">Uruguay 258</li><li class="icon-phone">0343 15-464-0229</li><li class="icon-clock">Lunes y Jueves 20:00 a 21:00hs</li></ul>', -31.731562, -60.525681],
      ['<h5>Shape Gym</h5><ul><li class="icon-location">Tratado del Pilar 272</li><li class="icon-phone">0343-155015661</li><li class="icon-clock">Viernes 15:00 a 16:30hs</li></ul>', -32.067003, -60.635031],
      ['<h5>Esc. NINA N° 3 "Alvarez de los Arenales"</h5><ul><li class="icon-location">Barrio Belgrano</li></ul>', -32.074977, -60.619740],
      ['<h5>Esc. Secundaria N° 7 "Dr. Carlos Lorenzo Vergara"</h5><ul><li class="icon-location">Sarmiento 301</li><li class="icon-phone">0343-4981224</li></ul>', -32.067850, -60.636184],
      ['<h5>Consultario de Kinesiología y Fisiatria</h5><ul><li class="icon-location">Av. San Martín 1038</li><li class="icon-phone">0343-154749927</li><li class="icon-clock">Martes 17:00 a 19:00hs</li></ul>', -32.029443, -60.557849]      
  ];
  var estilos = [ 
    { "featureType": "landscape", "stylers": [ { "color": "#ffffff" } ] },
    { "featureType": "road", "elementType": "geometry", "stylers": [ { "color": "#cccccc" } ] },
    // { "featureType": "road", "elementType": "text", "stylers": [ { "color": "#000000" } ] },
    { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#d8f0cf" } ] },
    { "featureType": "water", "stylers": [ { "color": "#d8f0cf" } ] } 
    ];
  var latlng = new google.maps.LatLng(-31.892097, -60.603578);
  var mapOptions = {
    zoom: 10,
    center: latlng,
    mapTypeControl: false,
    scaleControl: false,
    panControl: false,
    rotateControl: false,
    zoomControl: false,
    streetViewControl: false,
    styles: estilos,
    scrollwheel: false
  };
  var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
  var infowindow = new google.maps.InfoWindow();
  var marker;
  var i;
  for (i = 0; i < markers.length; i++) {
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(markers[i][1], markers[i][2]),
      map: map,
      icon: './assets/media/location-pin.png'
    });
    google.maps.event.addListener(marker, 'click', (function (marker, i) {
      return function () {
        infowindow.setContent(markers[i][0]);
        infowindow.open(map, marker);
      };
    })(marker, i));
  }

  //movimientos del mapa
  $(".show-parana").on("click", function(){
    map.setCenter(new google.maps.LatLng(-31.732793, -60.531803));
    map.setZoom(15);
  });
  $(".show-diamante").on("click", function(){
    map.setCenter(new google.maps.LatLng(-32.071182, -60.628710));
    map.setZoom(15);
  });
  $(".show-aldeaprotestante").on("click", function(){
    map.setCenter(new google.maps.LatLng(-32.029443, -60.557849));
    map.setZoom(13);
  });

}


/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $('ul.tabs').tabs();
  $('.parallax').parallax();
  $('.scrollspy').scrollSpy();
  $('.slider').slider();
}


/* 
 * Ejecuto las llamadas a fancybox
 *
 * - Ejecucion en: $(document).ready()
 */
function fancyBoxes() {
  $(".htmlpopup").fancybox({
    'width':600,
    'autoSize' : false
  });
  $('.showimg').fancybox({
    padding: 0
  });   
}


/*
* Obtengo la altura de los elementos y la estandarizo para todos
*
* - Ejecucion en: $(window).load(), $(window).resize()
*/
function sameHeight() {
  var altnews = 0;
  $(".news-article .card").each(function() {
    if ($(this).height() > altnews) altnews=$(this).height();
  }).css("height", altnews+50);

  var altgal = 0;
  $(".gallery-item").each(function() {
    if ($(this).height() > altgal) altgal=$(this).height();
  }).css("height", altgal);
}


/* 
 * Envío del formulario de contacto
 *
 * - Ejecucion en: $(document).ready()
 */
function contactFormGo(){

  $("#contact-submit").on('click', function(e){//formulario de contacto
    e.preventDefault();

    var okflag=true;
    $('.contact-form input, .contact-form textarea').filter('[required]:visible').each(function(i, requiredField){
      if($(requiredField).val()==''){
        if(okflag==true)$('#msg-all').fadeIn().delay(5000).fadeOut();
        okflag=false; 
        $(requiredField).addClass("contact-error");
      }
      else $(requiredField).removeClass("contact-error");
    });

    if(okflag==true)
      $('#msg').addClass("backGload").fadeIn().load('contacto.php', {
        nombre: $('#contact-name').val(),
        telefono: $('#contact-phone').val(),    
        email: $('#contact-email').val(),
        mensaje: $('#contact-message').val()
      }, function(){
        $('input, textarea').val("");
        $('#msg').removeClass("backGload");
      });

  });

}


/*
 * 
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function () {

  showDescription();
  goMaterialize();
  if($("#map-canvas").length) googleMaps();
  fancyBoxes();
  contactFormGo();

});

$(window).load(function () {
  
  sameHeight();

});

$(window).resize(function () {
  
  // sameHeight();

});